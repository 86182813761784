@import './variables.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,700&display=swap');

*, *::before, *::after {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

body {
    font-family: $lato-sans;
    background-color: white;
}


.section-hero {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;

    .bg {
        background-image: url('../assets/bg-1.jpg');
        width: 100%;
        height: 100vh;
        background-size: cover;
        position: absolute;
        z-index: -9999;
        background-position: center;
        top: 72px;
    }

    .header-container {
        h1 {
            font-size: 50px;
            font-weight: 900;
            color: white;
        }

        .subtitle-thin {
            font-size: 23px;
            font-weight: lighter;
            color: white;
        }

        .subtitle-regular {
            font-size: 23px;
            font-weight: 600;
            color: white;
            line-height: 35px;
        }

        .sign {
            font-size: 18px;
            font-weight: 600;
            color: white;
            line-height: 60px;
        }
    }
}

@media only screen and (min-width: 420px) {
    .section-hero {
        height: 100vh;
        .header-container {
            h1 {
                font-size: 80px;
            }

            .subtitle-thin {
                font-size: 45px;
            }

            .subtitle-regular {
                font-size: 45px;
                line-height: 48px;
            }

            .sign {
                font-size: 22px;
                line-height: 76px;
            }
        }
    }
}
.section-prefooter {
    background-color: #6552ab;
    .cont-address {
        display: flex;
        align-items: center;
        .cont-text {
            padding: 50px;
            border: 2px solid white;
            width: 100%;
            margin-top: 30px;
            .title {
                font-weight: bold;
                font-size: 30px;
                color: white;
            }
            .text {
                font-size: 22px;
                color: white;
            }
        }
    }
}

@media only screen and (min-width: 420px) {
    .section-prefooter {    
        .cont-address {
            align-items: baseline;
            .cont-text {
                margin-left: 50px;
                width: 60%;
            }
        }
    }
}
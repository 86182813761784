.tour-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .bg {
        background-image: url('../assets/bg-3.jpg');
        width: 100%;
        height: 100vh;
        background-size: cover;
        position: absolute;
        background-repeat: repeat;
        background-position-x: 85%;
        z-index: -1;
    }

    h5 {
        font-size: 18px;
        font-weight: 700;
        color: white;
    }
    .text {
        color: white;
        font-size: 18px;
        line-height: 28px;
    }
}

@media only screen and (min-width: 420px) {
    .tour-section {
        .bg {
            background-position-y: 100%;
        }
        h5 {
            font-size: 26px;
        }
        .text {
            font-size: 22px;
            line-height: 43px;
        }
    }
}
.section-proposal {
    background-color: white;
    span {
        font-size: 20px;
        font-weight: 900;
        color: #6552ab;
        line-height: 22px;
    }
    .color {
        color: #dab543;
    }
}

@media only screen and (min-width: 420px) {
    .section-proposal {
        span {
            font-size: 80px;
            line-height: 110px;
        }
    }
}
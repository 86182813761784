@import '../../styles/variables.scss';

.footer-section {
    width: 100%;
    background-color: $secondary-color;
    padding: 1.2rem;
    border-bottom: 1px solid $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-weight: bold;
        color: white;
        font-size: 15px;
    }
}
.who-section {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    background: #6552ab;
    .header-section {
        font-weight: bolder;
        font-size: 24px;
        color: white;
    }
    .icon {
        width: 12%;
    }
    .text {
        color: white;
        font-size: 18px;
        line-height: 22px;
    }
}

@media only screen and (min-width: 420px) {
    .who-section {
        height: 100vh;
        padding-top: 0px;
        padding-bottom: 0px;
        background: none;

        .bg {
            background-image: url('../assets/bg-2.jpg');
            width: 100%;
            height: 100vh;
            background-size: cover;
            position: absolute;
            z-index: -9999;
            background-position-y: 80%;
        }

        .header-section {
            font-weight: bolder;
            font-size: 50px;
            color: white;
        }

        .text {
            color: white;
            font-size: 22px;
            line-height: 36px;
        }
    }
}